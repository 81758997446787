@tailwind base;
@tailwind components;
@tailwind utilities;

.sidebar {
  position: absolute;
  top: 5rem;
  left: 1rem;
  width: 14rem;
  z-index: 100;
}
.graph-canvas {
  width: 100%;
  height: 100vh;
}
.login {
  width: 100%;
  max-width: 25rem;
  display: block;
  margin: 10rem auto;
}
.form-error {
  @apply text-sm text-red-500;
}
.form-label {
  @apply text-sm block;
}
.form-control {
  @apply w-full rounded-md bg-white px-3.5 py-2.5 text-sm shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50;
}
.btn {
  @apply rounded-md bg-white px-3.5 py-2.5 text-sm font-semibold shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50;
}
